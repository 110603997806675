import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AboutStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Scheduled Action feature is used to plan automatic actions for
        future dates on a document and to send notifications about it to
        specific recipients.
      </Paragraph>
    </StepContentDiv>
  );
}
