import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ActionStep() {
  return (
    <StepContentDiv>
      <Paragraph>There are specific action that can be planned: </Paragraph>
      <Paragraph>
        Archive - This action archives a document and sends a confirmation email
        on the action date.
      </Paragraph>

      <Paragraph>
        Delete - This action deletes a document and sends a confirmation email
        on the action date.
      </Paragraph>

      <Paragraph>
        Send Email - This action only sends an email scheduled for the action
        date, without making any changes to a document.
      </Paragraph>
    </StepContentDiv>
  );
}
