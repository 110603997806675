import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import ScheduledActionMessage, {
  Action,
  ScheduledActionInfo,
} from "../../models/scheduledActionMessage";

export interface ScheduledActionState {
  documentActions: ScheduledActionInfo[];
  scheduledAction: ScheduledActionMessage;
  originalAction: ScheduledActionMessage;
  error: ErrorState;
  isLoading: boolean;
  isSet: boolean;
}

interface ErrorState {
  isError: boolean;
  isErrorVisible: boolean;
  isEmailNoteError: boolean;
}

const initialState: ScheduledActionState = {
  documentActions: [],
  scheduledAction: {
    action: undefined,
    expirationDate: undefined,
    emailContent: "",
    emailRecipients: [],
  },
  originalAction: {
    action: undefined,
    expirationDate: undefined,
    emailContent: "",
    emailRecipients: [],
  },
  error: {
    isError: false,
    isErrorVisible: false,
    isEmailNoteError: false,
  },
  isLoading: false,
  isSet: false,
};

export const scheduledActionSlice = createSlice({
  name: "scheduledAction",
  initialState: initialState,
  reducers: {
    setDocumentActions(state, action: PayloadAction<ScheduledActionInfo[]>) {
      state.documentActions = action.payload;
    },
    setScheduledAction(state, action: PayloadAction<Action>) {
      state.scheduledAction.action = action.payload;
    },
    setScheduledActionIsSet(state, action: PayloadAction<boolean>) {
      state.isSet = action.payload;
    },
    setScheduledEmailContent(state, action: PayloadAction<string>) {
      state.scheduledAction.emailContent = action.payload;
    },
    setScheduledEmailRecipient(state, action: PayloadAction<string[]>) {
      state.scheduledAction.emailRecipients = [...action.payload];
    },
    setScheduledExpirationDate(
      state,
      action: PayloadAction<string | undefined>
    ) {
      state.scheduledAction.expirationDate = action.payload;
    },
    setScheduledActionIsError(state, action: PayloadAction<boolean>) {
      state.error.isError = action.payload;
    },
    setScheduledActionIsErrorVisible(state, action: PayloadAction<boolean>) {
      state.error.isErrorVisible = action.payload;
    },
    setScheduledActionIsEmailNoteError(state, action: PayloadAction<boolean>) {
      state.error.isEmailNoteError = action.payload;
    },
    setScheduledActionContent(
      state,
      action: PayloadAction<ScheduledActionMessage | null>
    ) {
      if (action.payload) {
        state.scheduledAction = action.payload;
        state.originalAction = action.payload;
      } else {
        state.scheduledAction = initialState.scheduledAction;
        state.originalAction = initialState.originalAction;
      }
    },
    setScheduledActionIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    clearScheduledAction(state) {
      state.error = initialState.error;
      state.scheduledAction = state.originalAction;
    },
  },
});

export const {
  setScheduledAction,
  setScheduledEmailContent,
  setScheduledEmailRecipient,
  setScheduledExpirationDate,
  setScheduledActionIsError,
  setScheduledActionContent,
  setScheduledActionIsErrorVisible,
  setScheduledActionIsEmailNoteError,
  setScheduledActionIsLoading,
  clearScheduledAction,
  setScheduledActionIsSet,
  setDocumentActions,
} = scheduledActionSlice.actions;

export const selectScheduledAction = (state: RootState) =>
  state.scheduledAction.scheduledAction;
export const selectScheduledActionExists = (state: RootState) =>
  state.scheduledAction.isSet;
export const selectScheduledActionIsError = (state: RootState) =>
  state.scheduledAction.error.isError;
export const selectScheduledActionIsErrorVisible = (state: RootState) =>
  state.scheduledAction.error.isErrorVisible;
export const selectScheduledActionIsFilled = (state: RootState) =>
  state.scheduledAction.scheduledAction.action !== undefined &&
  state.scheduledAction.scheduledAction.expirationDate !== undefined;
export const selectScheduledActionEmailNoteError = (state: RootState) =>
  state.scheduledAction.error.isEmailNoteError;
export const selectScheduledActionIsLoading = (state: RootState) =>
  state.scheduledAction.isLoading;
export const selectDocumentActions = (state: RootState) =>
  state.scheduledAction.documentActions;
export const selectScheduledActionExpirationDate = (state: RootState) =>
  state.scheduledAction.scheduledAction.expirationDate;

export default scheduledActionSlice.reducer;
