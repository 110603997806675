import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../selfHelpPopup/SC/StyledDialogContentText";

export interface ScheduledActionPopupProps {
  isOpen: boolean;
  actionText?: string;
  onClose: () => void;
  onDeleteAction: () => void;
  onKeepAction: () => void;
}

export function ScheduledActionDecisionPopup(props: ScheduledActionPopupProps) {
  return (
    <StyledDialog
      id={"set-deleted-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-deleted-dialog-title"}>
        {"Changing document with scheduled action"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-deleted-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-deleted-dialog-description">
          This document revision has{" "}
          <span style={{ fontWeight: "bold" }}>{props.actionText}</span> action
          scheduled. Changing this document will impact this action. Please
          choose an option.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <TooltipTop title="Delete scheduled action associated with this document.">
          <DialogButton
            id={"set-deleted-dialog-delete-button"}
            color="primary"
            onClick={props.onDeleteAction}
            autoFocus
          >
            {"Delete"}
          </DialogButton>
        </TooltipTop>
        <TooltipTop title="Retain the scheduled action without change.">
          <DialogButton
            id={"set-deleted-dialog-keep-button"}
            color="primary"
            onClick={props.onKeepAction}
            autoFocus
          >
            {"Keep"}
          </DialogButton>
        </TooltipTop>
        <DialogButton
          id={"set-deleted-dialog-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
