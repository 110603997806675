import { useAppSelector } from "../hooks";
import {
  selectIsDocumentFromServiceIntegration,
  selectDocumentDraftType,
  selectCurrentDocumentCma,
} from "../../slices/documentMetadataSlice";
import { CountryAvailabilityType } from "../../../models/documentDetails/countryMarketAvailability";
import { selectScheduledActionExists } from "../../slices/scheduledActionSlice";

export function useDocumentState() {
  const isDocumentFromServiceIntegration = useAppSelector(
    selectIsDocumentFromServiceIntegration
  );
  const documentDraftType = useAppSelector(selectDocumentDraftType);
  const isCmaRestricted =
    useAppSelector(selectCurrentDocumentCma).countryAvailabilityType !==
    CountryAvailabilityType.AllCountries;

  const hasScheduledAction = useAppSelector(selectScheduledActionExists);

  return {
    isDocumentFromServiceIntegration,
    documentDraftType,
    isCmaRestricted,
    hasScheduledAction,
  };
}
