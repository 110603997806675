import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AdditionalEmailsStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, additional recipients for the scheduled action can be
        specified. By default, the creator of the scheduled action is included
        as an email recipient.
      </Paragraph>
    </StepContentDiv>
  );
}
