import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../selfHelpPopup/SC/StyledDialogContentText";

export interface ScheduledActionDeleteInfoPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  actionText?: string;
}

export function ScheduledActionDeleteInfoPopup(
  props: ScheduledActionDeleteInfoPopupProps
) {
  return (
    <StyledDialog
      id={"delete-scheduled-action-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"delete-scheduled-action-dialog-title"}>
        {"Changing document with scheduled action"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"delete-scheduled-action-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="delete-scheduled-action-dialog-description">
          This document revision has{" "}
          <span style={{ fontWeight: "bold" }}>{props.actionText}</span> action
          scheduled. If you proceed with this change, the action will be
          removed.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton
          id={"delete-scheduled-action-dialog-proceed-button"}
          color="primary"
          onClick={props.onConfirm}
          autoFocus
        >
          {"Proceed"}
        </DialogButton>
        <DialogButton
          id={"delete-scheduled-action-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
