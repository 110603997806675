import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";
import { DraftType } from "../../../models/documentDetails/documentStatus";
import { Workflow } from "../../../models/documentList/types/workflow";
import { ErrorApiResponse } from "../../../models/errorApi";
import { ErrorApiType } from "../../../models/errorApiType";
import { SnackbarType } from "../../../models/snackbar";
import { isSecurityLevelNonLimited } from "../../helpers/securityLevelHelper";
import { openSnackbar } from "../../helpers/snackBarHelper";
import {
  setIsUpdatingDocument,
  setUsePrompt,
} from "../../slices/documentDetailsSlice";
import {
  selectCurrentDocumentMetadata,
  selectDocumentDraftType,
  selectIsTrimmedIdentitySectionEdited,
} from "../../slices/documentMetadataSlice";
import {
  selectHasAnySectionNotVisiblePublishError,
  selectIsPublishValidationOn,
} from "../../slices/documentMetadataValidationSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useDocumentFlow } from "./useDocumentFlow";
import usePublishApi from "./usePublishApi";

export function usePublishDocumentAction(
  userEmail: string,
  isApprovalButton?: boolean
) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isIdentityChangedPopupOpen, setIsIdentityChangedPopupOpen] =
    useState(false);
  const identityIsEdited = useAppSelector(selectIsTrimmedIdentitySectionEdited);
  const draftType = useAppSelector(selectDocumentDraftType);
  const currentDocumentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const hasAnyNotVisibleErrors = useAppSelector(
    selectHasAnySectionNotVisiblePublishError
  );
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const { isNewDocumentRevision, isNewDocumentRevisionDraft } =
    useDocumentFlow();

  const {
    publishApiData: {
      isSuccess: isPublishSuccess,
      isLoading: isPublishDocumentLoading,
      error: publishDocumentError,
    },
    publish,
    publishNewDocumentRevision,
  } = usePublishApi();

  useEffect(() => {
    if (isPublishDocumentLoading) {
      dispatch(setUsePrompt(false));
      dispatch(setIsUpdatingDocument(true));
    }
  }, [dispatch, isPublishDocumentLoading]);

  const publishDocumentAction = (
    shouldOldLinksWorkOnIdentityChange?: boolean,
    shouldKeepScheduledAction?: boolean
  ) => {
    if (!hasAnyNotVisibleErrors || isPublishValidationOn) {
      const documentMetadata = cloneDeep(currentDocumentMetadata);
      const originalWorkflow = documentMetadata.workflow;

      if (documentMetadata.workflow === Workflow.NewDocument) {
        documentMetadata.workflow = Workflow.Draft;
      }

      documentMetadata.documentIdentity.documentNumber =
        documentMetadata.documentIdentity.documentNumber.trim();
      documentMetadata.documentIdentity.documentPart =
        documentMetadata.documentIdentity.documentPart.trim();
      documentMetadata.publisherEmail = userEmail;
      documentMetadata.accessGroups = isSecurityLevelNonLimited(
        documentMetadata.securityLevel
      )
        ? []
        : documentMetadata.accessGroups;

      if (isNewDocumentRevision || isNewDocumentRevisionDraft) {
        if (!isApprovalButton) {
          documentMetadata.workflow = Workflow.Published;
        }
        publishNewDocumentRevision(documentMetadata, shouldKeepScheduledAction);
      } else {
        publish(
          documentMetadata,
          originalWorkflow,
          shouldOldLinksWorkOnIdentityChange,
          shouldKeepScheduledAction
        );
      }
    }
  };

  const publishingErrors = useMemo(() => {
    if (!isPublishSuccess && publishDocumentError) {
      return "status" in publishDocumentError &&
        !!(publishDocumentError.data as ErrorApiResponse | undefined)?.errors
        ? (publishDocumentError.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }

    return [];
  }, [isPublishSuccess, publishDocumentError]);

  useEffect(() => {
    if (publishDocumentError) {
      dispatch(setUsePrompt(true));
      dispatch(setIsUpdatingDocument(false));
    }
  }, [dispatch, publishDocumentError]);

  const onDocumentPublishSuccess = useCallback(() => {
    navigate("/");
    openSnackbar(
      dispatch,
      SnackbarMsg.PublishDocumentSuccess,
      SnackbarType.success
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isPublishSuccess) {
      dispatch(setIsUpdatingDocument(false));
      onDocumentPublishSuccess();
    }
  }, [dispatch, onDocumentPublishSuccess, isPublishSuccess]);

  const publishButtonHandler = (keepScheduledAction: boolean) => {
    if (
      identityIsEdited &&
      draftType != DraftType.NewDocumentDraft &&
      draftType != DraftType.NewDocumentRevision
    ) {
      setIsIdentityChangedPopupOpen(true);
    } else {
      publishDocumentAction(false, keepScheduledAction);
    }
  };

  const onYesClick = (keepScheduledAction: boolean) => {
    setIsIdentityChangedPopupOpen(false);
    publishDocumentAction(true, keepScheduledAction);
  };

  const onNoClick = (keepScheduledAction: boolean) => {
    setIsIdentityChangedPopupOpen(false);
    publishDocumentAction(false, keepScheduledAction);
  };

  const onCloseClick = () => {
    setIsIdentityChangedPopupOpen(false);
  };

  return {
    isIdentityChangedPopupOpen,
    onYesClick,
    onNoClick,
    onCloseClick,
    publishButtonHandler,
    publishingErrors,
  };
}
