import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DateOfActionStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        There are two options for selecting the effective date of the action.
        The first option is to select a specified date range when the action on
        a document will take place.
      </Paragraph>
    </StepContentDiv>
  );
}
