import EventNoteIcon from "@mui/icons-material/EventNote";
import { Badge } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useGetDocumentActionMutation } from "../../../../../apis/scheduledActionsApi";
import { getNonEmptyDocumentIdentity } from "../../../../../app/helpers/documentIdentityHelper";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectShouldRefreshDocumentMetadata } from "../../../../../app/slices/documentDetailsSlice";
import { selectDocumentIdentity } from "../../../../../app/slices/documentMetadataSlice";
import {
  selectScheduledActionExists,
  setScheduledActionContent,
  setScheduledActionIsErrorVisible,
  setScheduledActionIsLoading,
  setScheduledActionIsSet,
} from "../../../../../app/slices/scheduledActionSlice";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { SnackbarType } from "../../../../../models/snackbar";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { ScheduledActionDialog } from "./ScheduledActionDialog";

interface ScheduledActionButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
}

export function ScheduledActionButton(props: ScheduledActionButtonProps) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const scheduledActionExists = useAppSelector(selectScheduledActionExists);
  const documentIdentity = useAppSelector(selectDocumentIdentity);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );
  const shouldRefreshDocumentMetadata = useAppSelector(
    selectShouldRefreshDocumentMetadata
  );

  const notEmptyIdentity = useMemo(
    () => getNonEmptyDocumentIdentity(documentIdentity),
    [documentIdentity]
  );

  const [getScheduledActions, { data: scheduledAction, isLoading, isError }] =
    useGetDocumentActionMutation();

  const debounceSetLoadingToFalse = debounce(function () {
    dispatch(setScheduledActionIsLoading(false));
    dispatch(setScheduledActionIsErrorVisible(false));
  }, 1000);

  const onClose = useCallback(() => {
    setIsOpen(false);
    debounceSetLoadingToFalse();
  }, [debounceSetLoadingToFalse]);

  const title = useMemo(() => {
    if (scheduledAction) {
      return "Scheduled action";
    } else {
      return "Schedule new action";
    }
  }, [scheduledAction]);

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.ScheduledActionsError,
        SnackbarType.error
      );
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (shouldRefreshDocumentMetadata || notEmptyIdentity.documentNumber) {
      void getScheduledActions(notEmptyIdentity);
    }
  }, [getScheduledActions, shouldRefreshDocumentMetadata, notEmptyIdentity]);

  useEffect(() => {
    if (scheduledAction) {
      dispatch(removeVisibleButton(ActionButton.Add));
      dispatch(setScheduledActionContent(scheduledAction));
      dispatch(setScheduledActionIsSet(true));
    } else {
      dispatch(addVisibleButton(ActionButton.Add));
      dispatch(setScheduledActionContent(null));
      dispatch(setScheduledActionIsSet(false));
    }
  }, [dispatch, scheduledAction]);

  return (
    <ButtonDiv>
      <ScheduledActionDialog
        isOpen={isOpen}
        onCloseClick={onClose}
        title={title}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-scheduled-actions-button"}
        text={"Scheduled action"}
        tooltipText={
          isLoading
            ? TooltipMsg.IsLoadingScheduledActions
            : TooltipMsg.ShowScheduledActions
        }
        disabledTooltipText={
          hasUncompletedSections
            ? TooltipMsg.UncompletedSelfHelpSections
            : props.disabledTooltipText
        }
        disabled={props.disabled || isLoading || hasUncompletedSections}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={
          <Badge
            color={"primary"}
            variant="dot"
            invisible={!scheduledActionExists}
          >
            <EventNoteIcon fontSize="small" />
          </Badge>
        }
      />
    </ButtonDiv>
  );
}
