import { useMemo, useState } from "react";
import { useAppSelector } from "../hooks";
import { selectAttachmentUploadInfo } from "../../slices/fileUploadSlice";
import { useNavigate } from "react-router-dom";
import { useRouteHelper } from "../useRouteHelper";
import { Workflow } from "../../../models/documentList/types/workflow";
import { selectDocumentIdentity } from "../../slices/documentMetadataSlice";
import { useDocumentFlow } from "./useDocumentFlow";

export function useDuplicateAttachment() {
  const navigate = useNavigate();
  const { getUrlToDocumentByIdentity } = useRouteHelper();
  const { isNewDocumentRevision, isNewDocumentVersion, isNewDocument } =
    useDocumentFlow();
  const [isDuplicateAttachmentPopupOpen, setIsDuplicateAttachmentPopupOpen] =
    useState(false);
  const attachmentInfo = useAppSelector(selectAttachmentUploadInfo);
  const originalDocumentIdentity = attachmentInfo?.documentOriginalIdentity;
  const { documentLanguageCodes, documentNumber, documentPart, revision } =
    useAppSelector(selectDocumentIdentity);

  const attachmentIsDuplicate = useMemo(() => {
    if (attachmentInfo?.isDuplicate && originalDocumentIdentity) {
      if (isNewDocumentRevision || isNewDocumentVersion || isNewDocument)
        return attachmentInfo.isDuplicate;

      return !(
        documentLanguageCodes.join("") ===
          originalDocumentIdentity.documentLanguageCodes &&
        documentNumber === originalDocumentIdentity.documentNumber &&
        documentPart === originalDocumentIdentity.documentPart &&
        revision === originalDocumentIdentity.revision
      );
    }

    return false;
  }, [
    documentLanguageCodes,
    documentNumber,
    documentPart,
    attachmentInfo,
    originalDocumentIdentity,
    isNewDocumentRevision,
    revision,
    isNewDocumentVersion,
    isNewDocument,
  ]);

  const navigateToOriginalDocumentHandler = () => {
    if (attachmentInfo && attachmentInfo.documentOriginalIdentity) {
      const url = getUrlToDocumentByIdentity({
        identity: {
          ...attachmentInfo.documentOriginalIdentity,
          documentLanguageCodes: [
            attachmentInfo.documentOriginalIdentity.documentLanguageCodes,
          ],
        },
        workflow: Workflow.Published,
      });
      navigate(`/document/${url}`);
    }
  };

  const closePopupHandler = () => {
    setIsDuplicateAttachmentPopupOpen(false);
  };

  const openPopupHandler = () => {
    setIsDuplicateAttachmentPopupOpen(true);
  };

  return {
    isDuplicateAttachmentPopupOpen,
    attachmentIsDuplicate,
    originalDocumentIdentity,
    navigateToOriginalDocumentHandler,
    closePopupHandler,
    openPopupHandler,
  };
}
