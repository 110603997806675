import { EventNote } from "@mui/icons-material";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import { useTheme } from "styled-components";

export function ScheduledActionStep(props: ContextMenuStepProps) {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        {!props.isVisible && (
          <>
            <EventNote
              style={{
                fontSize: "16px",
                verticalAlign: "middle",
                color: theme.palettes.grayscale.dark,
              }}
            />
            <b> SCHEDULED ACTION</b> -{" "}
          </>
        )}
        This action allows a user with editor rights to a document to schedule
        an action (Archive, Delete, Send Email) on it. If the user does not have
        editing access to the document, the scheduled action details are
        available in read-only mode.
      </Paragraph>
    </StepContentDiv>
  );
}
