import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDuplicateAttachment } from "../../../../../app/hooks/document/useDuplicateAttachment";
import { usePublishDocumentAction } from "../../../../../app/hooks/document/usePublishDocumentAction";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { setDocumentAction } from "../../../../../app/slices/documentMetadataSlice";
import {
  selectHasAnySectionNotVisiblePublishError,
  selectHasCurrentDocumentAnyValidationErrors,
  setIsPublishDocumentValidationOn,
} from "../../../../../app/slices/documentMetadataValidationSlice";
import {
  selectDocumentActions,
  selectScheduledAction,
} from "../../../../../app/slices/scheduledActionSlice";
import { Actions } from "../../../../../models/documentDetails/actions";
import { ShowPopups } from "../../../../../models/documentDetails/publishButtonPopupProps";
import {
  Action,
  ScheduledActionInfo,
} from "../../../../../models/scheduledActionMessage";
import { ScheduledActionDecisionPopup } from "../../../scheduledActionPopup/ScheduledActionDecisionPopup";
import { ScheduledActionDeleteInfoPopup } from "../../../scheduledActionPopup/ScheduledActionDeleteInfoPopup";
import { ScheduledActionKeepInfoPopup } from "../../../scheduledActionPopup/ScheduledActionKeepInfoPopup";
import { DiscardPendingApprovalPopup } from "../publish/DiscardPendingApprovalPopup";
import DuplicateAttachmentPopup from "../publish/DuplicateAttachmentPopup";
import { IdentityChangeConfirmationDialog } from "../publish/IdentityChangeConfirmationDialog";
import { NewRevisionPopup } from "../publish/NewRevisionPopup";
import PublishErrorHandler from "../publish/PublishErrorHandler";
import WarningPopupQueue from "./warning/WarningPopupQueue";

interface PublishButtonPopupsProps {
  id: string;
  userEmail: string;
  setShowPopups: Dispatch<SetStateAction<ShowPopups>>;
  showPopups: ShowPopups;
  setButtonHandlerClicked: Dispatch<SetStateAction<boolean>>;
  buttonHandlerClicked: boolean;
  isApproveButton?: boolean;
}

export function PublishButtonPopups(props: PublishButtonPopupsProps) {
  const dispatch = useAppDispatch();
  const [isActionPopupOpen, setIsActionPopupOpen] = useState(false);
  const [isDeleteActionPopupOpen, setIsDeleteActionPopupOpen] = useState(false);
  const [isKeepActionPopupOpen, setIsKeepActionPopupOpen] = useState(false);
  const [warningPopupIsOpen, setWarningPopupIsOpen] = useState(false);
  const [newRevisionPopupIsOpen, setNewRevisionPopupIsOpen] = useState(false);
  const [discardPendingApprovalsIsOpen, setDiscardPendingApprovalsIsOpen] =
    useState(false);
  const [shouldKeepAction, setShouldKeepAction] = useState(true);
  const hasAnyValidationsErrors = useAppSelector(
    selectHasCurrentDocumentAnyValidationErrors
  );
  const hasAnyNonVisibleValidationsErrors = useAppSelector(
    selectHasAnySectionNotVisiblePublishError
  );
  const actionType = useAppSelector(selectScheduledAction).action;
  const documentActions = useAppSelector(selectDocumentActions);

  const latestAction = useMemo(() => {
    return documentActions.find((x) => x.isLatest);
  }, [documentActions]);

  const actionText = useMemo(() => {
    const action = actionType ? actionType : latestAction?.action;
    return action === Action.SendEmail ? "Send Email" : action;
  }, [actionType, latestAction?.action]);

  const [scheduledActionsToKeep, setScheduledActionsToKeep] = useState<
    ScheduledActionInfo[]
  >([]);

  const {
    isDuplicateAttachmentPopupOpen,
    originalDocumentIdentity,
    navigateToOriginalDocumentHandler,
    closePopupHandler: closeDuplicatePopup,
    openPopupHandler: openDuplicatePopup,
  } = useDuplicateAttachment();

  const {
    publishButtonHandler,
    isIdentityChangedPopupOpen,
    onCloseClick,
    onNoClick,
    onYesClick,
    publishingErrors,
  } = usePublishDocumentAction(props.userEmail, props.isApproveButton);

  useEffect(() => {
    setScheduledActionsToKeep(
      documentActions.filter((x) => x.action === Action.Delete)
    );
  }, [documentActions]);

  const onWarningPopupSave = useCallback(() => {
    props.setShowPopups((popups) => ({ ...popups, showWarning: false }));
    setWarningPopupIsOpen(false);
  }, [props]);

  const onWarningPopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setWarningPopupIsOpen(false);
  }, [props]);

  const onNewRevisionPopupDelete = useCallback(() => {
    if (
      latestAction?.action === Action.Delete ||
      latestAction?.action === Action.SendEmail
    ) {
      props.setShowPopups((popups) => ({
        ...popups,
        showDeleteScheduledActionInfo: true,
        showScheduledActionsDecision: false,
      }));
      setScheduledActionsToKeep(
        documentActions.filter(
          (x) =>
            x.action === Action.Delete && x.revision != latestAction.revision
        )
      );
    }

    props.setShowPopups((popups) => ({
      ...popups,
      showNewRevision: false,
    }));
    dispatch(setDocumentAction(Actions.DeletePreviousRevision));
    setNewRevisionPopupIsOpen(false);
  }, [props, dispatch, latestAction, documentActions]);

  const onNewRevisionPopupArchive = useCallback(() => {
    if (
      latestAction?.action === Action.Delete ||
      latestAction?.action === Action.SendEmail
    ) {
      props.setShowPopups((popups) => ({
        ...popups,
        showDeleteScheduledActionInfo: false,
        showScheduledActionsDecision:
          latestAction.action === Action.Delete
            ? latestAction.hasPermissionToDelete
            : true,
      }));
      setScheduledActionsToKeep(
        documentActions.filter((x) => x.action === Action.Delete)
      );
    }

    props.setShowPopups((popups) => ({
      ...popups,
      showNewRevision: false,
    }));
    dispatch(setDocumentAction(Actions.ArchivePreviousRevision));
    setNewRevisionPopupIsOpen(false);
  }, [props, dispatch, latestAction, documentActions]);

  const onNewRevisionPopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setNewRevisionPopupIsOpen(false);
  }, [props]);

  const onDuplicatePopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    closeDuplicatePopup();
  }, [props, closeDuplicatePopup]);

  const onDuplicatePopupPublish = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showDuplicateAttachment: false,
    }));
    closeDuplicatePopup();
  }, [props, closeDuplicatePopup]);

  const onDiscardPendingApprovals = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showPendingApprovals: false,
    }));
    setDiscardPendingApprovalsIsOpen(false);
  }, [props]);

  const onDiscardPendingApprovalsClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setDiscardPendingApprovalsIsOpen(false);
  }, [props]);

  const onActionPopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setIsActionPopupOpen(false);
    setIsDeleteActionPopupOpen(false);
    setIsKeepActionPopupOpen(false);
  }, [props]);

  const onActionPopupKeep = useCallback(() => {
    setScheduledActionsToKeep(
      documentActions.filter((x) => x.action === Action.Delete)
    );

    props.setShowPopups((popups) => ({
      ...popups,
      showScheduledActionsDecision: false,
    }));
    setShouldKeepAction(true);
    setIsActionPopupOpen(false);
  }, [props, documentActions]);

  const onActionPopupDelete = useCallback(() => {
    if (props.showPopups.decisionPopupAction === Action.Delete) {
      setScheduledActionsToKeep((prev) =>
        prev.filter(
          (x) => x.revision !== props.showPopups.decisionPopupRevision
        )
      );
    }

    props.setShowPopups((popups) => ({
      ...popups,
      showScheduledActionsDecision: false,
    }));
    setShouldKeepAction(false);
    setIsActionPopupOpen(false);
  }, [props]);

  const onKeepActionsPoupConfirm = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showKeepScheduledActionsInfo: false,
    }));
    setIsKeepActionPopupOpen(false);
  }, [props]);

  const onDeleteActionPoupConfirm = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showDeleteScheduledActionInfo: false,
    }));
    setIsDeleteActionPopupOpen(false);
    setShouldKeepAction(false);
  }, [props]);

  useEffect(() => {
    if (props.buttonHandlerClicked) {
      dispatch(setIsPublishDocumentValidationOn(true));

      if (props.showPopups.showPendingApprovals) {
        setDiscardPendingApprovalsIsOpen(true);
        return;
      }

      if (props.showPopups.showDuplicateAttachment) {
        openDuplicatePopup();
        return;
      }

      if (props.showPopups.showWarning) {
        setWarningPopupIsOpen(true);
        return;
      }

      if (hasAnyValidationsErrors || hasAnyNonVisibleValidationsErrors) {
        props.setButtonHandlerClicked(false);
        return;
      }

      if (props.showPopups.showNewRevision) {
        setNewRevisionPopupIsOpen(true);
        return;
      }

      if (props.showPopups.showScheduledActionsDecision) {
        setIsActionPopupOpen(true);
        return;
      }

      if (props.showPopups.showDeleteScheduledActionInfo) {
        setIsDeleteActionPopupOpen(true);
        return;
      }

      if (
        props.showPopups.showKeepScheduledActionsInfo &&
        scheduledActionsToKeep.length > 0
      ) {
        setIsKeepActionPopupOpen(true);
        return;
      }

      props.setButtonHandlerClicked(false);
      publishButtonHandler(shouldKeepAction);
    }
  }, [
    props,
    openDuplicatePopup,
    publishButtonHandler,
    dispatch,
    hasAnyValidationsErrors,
    hasAnyNonVisibleValidationsErrors,
    shouldKeepAction,
    scheduledActionsToKeep.length,
  ]);

  return (
    <>
      <PublishErrorHandler errors={publishingErrors} />
      <DiscardPendingApprovalPopup
        id={"publish-discard-pending-approval-dialog"}
        isOpen={discardPendingApprovalsIsOpen}
        onCloseClick={onDiscardPendingApprovalsClose}
        onConfirmClick={onDiscardPendingApprovals}
      />
      <IdentityChangeConfirmationDialog
        id={"publish-identity-change-dialog"}
        onYesClick={() => onYesClick(shouldKeepAction)}
        onNoClick={() => onNoClick(shouldKeepAction)}
        onCloseClick={onCloseClick}
        isOpen={isIdentityChangedPopupOpen}
      />
      <DuplicateAttachmentPopup
        id="duplicate-attachment-popup"
        onCloseClick={onDuplicatePopupClose}
        onConfirmClick={navigateToOriginalDocumentHandler}
        onPublishAnywayClick={onDuplicatePopupPublish}
        publishAnywayButtonText="Publish anyway"
        isOpen={isDuplicateAttachmentPopupOpen}
        originalIdentity={originalDocumentIdentity}
      />
      <NewRevisionPopup
        isOpen={newRevisionPopupIsOpen}
        onArchive={onNewRevisionPopupArchive}
        onClose={onNewRevisionPopupClose}
        onDelete={onNewRevisionPopupDelete}
      />
      <WarningPopupQueue
        isOpen={warningPopupIsOpen}
        onSave={onWarningPopupSave}
        onClose={onWarningPopupClose}
      />
      <ScheduledActionDecisionPopup
        actionText={actionText}
        isOpen={isActionPopupOpen}
        onClose={onActionPopupClose}
        onDeleteAction={onActionPopupDelete}
        onKeepAction={onActionPopupKeep}
      />
      <ScheduledActionDeleteInfoPopup
        actionText={actionText}
        isOpen={isDeleteActionPopupOpen}
        onClose={onActionPopupClose}
        onConfirm={onDeleteActionPoupConfirm}
      />
      <ScheduledActionKeepInfoPopup
        actionText={Action.Delete}
        isOpen={isKeepActionPopupOpen}
        onClose={onActionPopupClose}
        onConfirm={onKeepActionsPoupConfirm}
        revisions={scheduledActionsToKeep.map((x) => x.revision)}
      />
    </>
  );
}
