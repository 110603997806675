export default interface ScheduledActionMessage {
  action?: Action;
  expirationDate?: string;
  userEmail?: string;
  emailContent?: string;
  emailRecipients?: string[];
}

export interface ScheduledActionInfo {
  action: Action;
  revision: string;
  isLatest: boolean;
  hasPermissionToDelete: boolean;
}

export enum Action {
  Archive = "Archive",
  Delete = "Delete",
  SendEmail = "SendEmail",
}
