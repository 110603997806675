import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function EmailNoteStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This field is optional and can be used to inform recipients about
        details of scheduled action on a document. It will be sent to recipients
        on the action date.
      </Paragraph>
    </StepContentDiv>
  );
}
