import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function OverwriteStep() {
  return (
    <StepContentDiv>
      <Paragraph>This button saves edited plan for scheduled action.</Paragraph>
    </StepContentDiv>
  );
}
