import { useCallback, useEffect, useState } from "react";
import useDocumentValidationRules from "../../../../../../app/hooks/document/useDocumentValidationRules";
import { useAppDispatch } from "../../../../../../app/hooks/hooks";
import {
  setScheduledActionIsEmailNoteError,
  setScheduledEmailContent,
  setScheduledEmailRecipient,
} from "../../../../../../app/slices/scheduledActionSlice";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";
import { MultiText } from "../../../../inputs/MultiText";
import { StyledScheduledActionDiv } from "../SC/StyledScheduledActionDiv";
import { ValidationStatus } from "../../../../../../models/validationRule";
import { useLazyGetAllUsersEmailQuery } from "../../../../../../apis/publishersApi";
import { DetailsAutoComplete } from "../../../../inputs/DetailsAutoComplete";
import { ComboBoxListItem } from "../../../../../../models/autocompleteValue";
import { debounce, orderBy } from "lodash";

interface EmailSectionProps {
  isEditable: boolean;
  emailBody: string | undefined;
  emailRecipients: string[] | undefined;
}

const encodeHTML = (input: string): string => {
  const map: { [key: string]: string } = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
  };
  return input.replace(/[&<>"']/g, (char) => map[char] || char);
};

const decodeHTML = (input: string): string => {
  const map: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };
  const regex = /&amp;|&lt;|&gt;|&quot;|&#39;/g;
  return input.replace(regex, (entity) => map[entity] || entity);
};

export function EmailSection(props: EmailSectionProps) {
  const dispatch = useAppDispatch();
  const { getEmailNoteValidationRules } = useDocumentValidationRules();
  const [closeTrigger, setCloseTrigger] = useState(false);
  const [searchedEmails, setSearchedEmails] = useState<ComboBoxListItem[]>([]);
  const [additionalEmails, setAdditionalEmails] = useState<ComboBoxListItem[]>(
    props.emailRecipients
      ? orderBy(
          props.emailRecipients,
          [(user) => user.toLowerCase()],
          ["asc"]
        ).map((e) => {
          return {
            key: e,
            value: e,
          };
        })
      : []
  );
  const [getUsers, { data, isLoading, isError, isFetching }] =
    useLazyGetAllUsersEmailQuery();

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      dispatch(
        setScheduledActionIsEmailNoteError(result === ValidationStatus.error)
      );
    },
    [dispatch]
  );

  const uniqueArray = (array: ComboBoxListItem[]) =>
    array.reduce((acc: ComboBoxListItem[], current: ComboBoxListItem) => {
      if (!acc.some((item) => item.value === current.value)) {
        acc.push(current);
      }
      return acc;
    }, []);

  const onChangeHandler = (newValues: ComboBoxListItem[]) => {
    const additionalEmails = uniqueArray(
      orderBy(newValues, [(user) => user.value.toLowerCase()], ["asc"]).map(
        (n) => {
          return {
            value: n.value.toLocaleLowerCase(),
            key: n.key.toLocaleLowerCase(),
          };
        }
      )
    );
    setAdditionalEmails(additionalEmails);
    dispatch(setScheduledEmailRecipient(additionalEmails.map((v) => v.value)));
    setCloseTrigger((s) => (s = !s));
  };

  const debouncedFetchApiData = debounce(getUsers, 500);

  useEffect(() => {
    if (props.isEditable) {
      void getUsers("");
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (data) {
      setSearchedEmails(
        data.map((x) => {
          return {
            key: x,
            value: x,
          };
        })
      );
    }
  }, [data]);

  return (
    <>
      <StyledScheduledActionDiv $margin={8}>
        <MultiText
          isEditable={props.isEditable}
          id="scheduled-action-email-body"
          inputValue={decodeHTML(props.emailBody ?? "")}
          wrapText={true}
          isLoading={false}
          onChangeHandler={(newValue: string) =>
            dispatch(setScheduledEmailContent(encodeHTML(newValue)))
          }
          isEdited={false}
          labelText="Additional Email note"
          placeholder="Additional note attached to the email (optional)"
          notEditableMessage={TooltipMsg.NoAccessToDocument}
          validationRules={getEmailNoteValidationRules()}
          onInputValidation={onInputValidation}
        />
      </StyledScheduledActionDiv>
      <StyledScheduledActionDiv $margin={8}>
        <DetailsAutoComplete
          id="scheduled-action-email-recipients-body"
          labelText="Additional Email recipients"
          selected={additionalEmails}
          items={searchedEmails}
          isEditable={props.isEditable}
          isDisabled={!props.isEditable}
          onRefreshHandler={() => void getUsers("")}
          isDictionaryDataFetchingError={isError}
          isLoading={isLoading}
          $paddingLeft={13}
          pillsClassName={"recipients-pill"}
          onChangeHandler={onChangeHandler}
          isEdited={false}
          multiple={true}
          notEditableMessage={TooltipMsg.NoAccessToDocument}
          onInputChange={(value: string) => {
            void debouncedFetchApiData(value);
          }}
          isFetching={isFetching}
          omitDataQualityCheck={true}
          placeholder={
            props.isEditable ? "Additional recipients (optional)" : ""
          }
          closeTrigger={closeTrigger}
        />
      </StyledScheduledActionDiv>
    </>
  );
}
