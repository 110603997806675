import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AddButtonStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This button saves the data and creates the scheduled action.
      </Paragraph>
    </StepContentDiv>
  );
}
