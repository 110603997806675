import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import { useMemo } from "react";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../selfHelpPopup/SC/StyledDialogContentText";

export interface ScheduledActionKeepInfoPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  actionText?: string;
  revisions: string[];
}

export function ScheduledActionKeepInfoPopup(
  props: ScheduledActionKeepInfoPopupProps
) {
  const revisionsText = useMemo(() => {
    if (props.revisions.length > 0) {
      return props.revisions.join(", ");
    }

    return "";
  }, [props.revisions]);

  return (
    <StyledDialog
      id={"keep-scheduled-action-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"keep-scheduled-action-dialog-title"}>
        {"Changing document with scheduled action"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"keep-scheduled-action-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="keep-scheduled-action-dialog-description">
          This document has{" "}
          <span style={{ fontWeight: "bold" }}>{props.actionText}</span>{" "}
          action(s) scheduled for these revisions: {" " + revisionsText}.{" "}
          <br></br> These actions will be kept. If you wish to modify them, it
          needs to be done on these revisions.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton
          id={"keep-scheduled-action-dialog-proceed-button"}
          color="primary"
          onClick={props.onConfirm}
          autoFocus
        >
          {"Proceed"}
        </DialogButton>
        <DialogButton
          id={"keep-scheduled-action-dialog-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
