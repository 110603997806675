import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useDocumentCategoriesState } from "../../../../../app/hooks/document/useDocumentCategoriesState";
import { useDocumentFlow } from "../../../../../app/hooks/document/useDocumentFlow";
import { useDuplicateAttachment } from "../../../../../app/hooks/document/useDuplicateAttachment";
import { useLinkCategoriesToDocument } from "../../../../../app/hooks/document/useLinkCategoriesToDocument";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { useUserPermissionsInCategory } from "../../../../../app/hooks/permissions/useUserPermissionsInCategory";
import { useUserPermissionsInDocument } from "../../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  selectIsBreakingDocumentLink,
  selectIsUpdatingDocument,
} from "../../../../../app/slices/documentDetailsSlice";
import {
  selectDocumentIdentity,
  selectHasOtherRevisionWithPendingApproval,
  selectHasPendingApproval,
  selectIsRevisionLatest,
} from "../../../../../app/slices/documentMetadataSlice";
import {
  selectHasCurrentDocumentAnyValidationErrors,
  selectHasTagsSectionAnyValidationWarnings,
  selectSecuritySectionStatus,
} from "../../../../../app/slices/documentMetadataValidationSlice";
import {
  selectIsAttachmentUploadInProgress,
  selectIsFileUploading,
} from "../../../../../app/slices/fileUploadSlice";
import { selectDocumentActions } from "../../../../../app/slices/scheduledActionSlice";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { ShowPopups } from "../../../../../models/documentDetails/publishButtonPopupProps";
import { Action } from "../../../../../models/scheduledActionMessage";
import { ValidationStatus } from "../../../../../models/validationRule";
import { ButtonDiv } from "../../../../documents/documentsHeader/SC/ButtonDiv";
import { PublishButtonPopups } from "../popups/PublishButtonPopups";

interface PublishButtonProps {
  id: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
  isApproveButton?: boolean;
  userEmail: string;
  text: string;
  color: "primary" | "secondary";
  canLinkWhenApprovalIsEnabled?: boolean;
}

export function PublishButton(props: PublishButtonProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const hasOtherRevisionWithPendingApproval = useAppSelector(
    selectHasOtherRevisionWithPendingApproval
  );
  const hasPendingApproval = useAppSelector(selectHasPendingApproval);
  const isLatestRevision = useAppSelector(selectIsRevisionLatest);
  const hasCurrentDocumentAnyValidationErrors = useAppSelector(
    selectHasCurrentDocumentAnyValidationErrors
  );
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isAttachmentUploadInProgress = useAppSelector(
    selectIsAttachmentUploadInProgress
  );
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const isFileUploaded = useMemo(() => {
    return !isFileUploading && !isAttachmentUploadInProgress;
  }, [isAttachmentUploadInProgress, isFileUploading]);
  const isBreakingDocumentLink = useAppSelector(selectIsBreakingDocumentLink);
  const { hasAccessToAnyDocumentCategory } =
    useUserPermissionsInCategory(undefined);
  const { hasPermissionToEditDocument, hasPermissionToDeleteDocument } =
    useUserPermissionsInDocument(false);
  const { isNewDocumentRevision, isNewDocumentRevisionDraft, isLaterRevision } =
    useDocumentFlow();
  const hasTagsSectionAnyValidationWarnings = useAppSelector(
    selectHasTagsSectionAnyValidationWarnings
  );
  const identity = useAppSelector(selectDocumentIdentity);
  const securitySectionStatus = useAppSelector(selectSecuritySectionStatus);
  const [showPopups, setShowPopups] = useState<ShowPopups>({
    showDuplicateAttachment: false,
    showWarning: false,
    showNewRevision: false,
    showPendingApprovals: false,
    showScheduledActionsDecision: false,
    showDeleteScheduledActionInfo: false,
    showKeepScheduledActionsInfo: false,
    decisionPopupAction: undefined,
    decisionPopupRevision: undefined,
  });
  const [buttonHandlerClicked, setButtonHandlerClicked] = useState(false);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );
  const showWarningPopup =
    securitySectionStatus === ValidationStatus.warning ||
    hasTagsSectionAnyValidationWarnings;

  const documentActions = useAppSelector(selectDocumentActions);
  const { attachmentIsDuplicate, originalDocumentIdentity } =
    useDuplicateAttachment();

  const { linkCategoriesButtonHandler } = useLinkCategoriesToDocument();
  const { categoriesWereModified } = useDocumentCategoriesState();

  const buttonHandler = () => {
    setShowPopups({
      showDuplicateAttachment: false,
      showWarning: false,
      showNewRevision: false,
      showPendingApprovals: false,
      showScheduledActionsDecision: false,
      showDeleteScheduledActionInfo: false,
      showKeepScheduledActionsInfo: false,
      decisionPopupAction: undefined,
      decisionPopupRevision: undefined,
    });

    if (
      (!hasPermissionToEditDocument.isEditable ||
        props.canLinkWhenApprovalIsEnabled) &&
      (hasAccessToAnyDocumentCategory || categoriesWereModified)
    ) {
      linkCategoriesButtonHandler();
      return;
    }

    setButtonHandlerClicked(true);

    if (
      !props.isApproveButton &&
      hasOtherRevisionWithPendingApproval &&
      (hasPendingApproval || isLatestRevision)
    ) {
      setShowPopups((popups) => ({ ...popups, showPendingApprovals: true }));
    }

    if (showWarningPopup) {
      setShowPopups((popups) => ({ ...popups, showWarning: true }));
    }

    if (attachmentIsDuplicate && originalDocumentIdentity) {
      setShowPopups((popups) => ({ ...popups, showDuplicateAttachment: true }));
    }

    if (
      hasPermissionToDeleteDocument.isEditable &&
      (isNewDocumentRevision || isLaterRevision)
    ) {
      setShowPopups((popups) => ({ ...popups, showNewRevision: true }));
    }

    let deleteActions = documentActions.filter(
      (x) => x.action === Action.Delete
    );
    const latestAction = documentActions.find((x) => x.isLatest);
    const revisionAction = documentActions.find(
      (x) => x.revision === identity.revision
    );

    if ((isNewDocumentRevision || isNewDocumentRevisionDraft) && latestAction) {
      if (
        latestAction.action === Action.SendEmail ||
        (latestAction.action === Action.Delete &&
          latestAction.hasPermissionToDelete)
      ) {
        setShowPopups((popups) => ({
          ...popups,
          showScheduledActionsDecision: true,
          decisionPopupRevision: latestAction.revision,
          decisionPopupAction: latestAction.action,
        }));
        deleteActions = deleteActions.filter(
          (x) => !(x.isLatest && x.hasPermissionToDelete)
        );
      }

      if (latestAction.action === Action.Archive) {
        setShowPopups((popups) => ({
          ...popups,
          showDeleteScheduledActionInfo: true,
        }));
      }
    }

    if (
      !isNewDocumentRevision &&
      !isNewDocumentRevisionDraft &&
      (revisionAction?.action === Action.Archive ||
        revisionAction?.action === Action.SendEmail ||
        (revisionAction?.action === Action.Delete &&
          revisionAction.hasPermissionToDelete))
    ) {
      setShowPopups((popups) => ({
        ...popups,
        showScheduledActionsDecision: true,
        decisionPopupRevision: revisionAction.revision,
        decisionPopupAction: revisionAction.action,
      }));
      deleteActions = deleteActions.filter(
        (x) => !(x.hasPermissionToDelete && x.revision === identity.revision)
      );
    }

    if (deleteActions.length > 0) {
      setShowPopups((popups) => ({
        ...popups,
        showKeepScheduledActionsInfo: true,
      }));
    }
  };

  useEffect(() => {
    if (props.userEmail) {
      dispatch(addVisibleButton(ActionButton.Publish));
    }

    if (!props.userEmail) {
      dispatch(addVisibleButton(ActionButton.PublishDoNotChangePublisher));
    }

    return () => {
      if (props.userEmail) {
        dispatch(removeVisibleButton(ActionButton.Publish));
      }

      if (!props.userEmail) {
        dispatch(removeVisibleButton(ActionButton.PublishDoNotChangePublisher));
      }
    };
  }, [dispatch, props.userEmail]);

  const tooltipText = useMemo(() => {
    if (hasUncompletedSections) {
      return TooltipMsg.UncompletedSelfHelpSections;
    }

    if (props.disabledTooltipText) {
      return props.disabledTooltipText;
    }

    if (hasCurrentDocumentAnyValidationErrors) {
      return `You cannot ${
        props.isApproveButton ? "approve" : "publish"
      } document because it has some validation errors`;
    }
  }, [
    hasUncompletedSections,
    props.disabledTooltipText,
    hasCurrentDocumentAnyValidationErrors,
    props.isApproveButton,
  ]);

  return (
    <ButtonDiv className={"document-details-publish-button"}>
      <PublishButtonPopups
        id={props.id}
        showPopups={showPopups}
        setShowPopups={setShowPopups}
        buttonHandlerClicked={buttonHandlerClicked}
        setButtonHandlerClicked={setButtonHandlerClicked}
        userEmail={props.userEmail}
        isApproveButton={props.isApproveButton}
      />
      <TextButtonControl
        colors={props.color}
        height={theme.shapes.primaryButtonHeight}
        id={props.id}
        text={props.text}
        disabled={
          hasUncompletedSections ||
          hasCurrentDocumentAnyValidationErrors ||
          !isFileUploaded ||
          isUpdatingDocument ||
          props.disabled ||
          isBreakingDocumentLink
        }
        disabledTooltipText={tooltipText}
        onClick={buttonHandler}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={<PublishedWithChangesIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
