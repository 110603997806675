import { useCallback, useEffect, useState } from "react";
import { useChangeDocumentWorkflowActionMutation } from "../../../apis/documentActionsApi";
import { Identity } from "../../../models/documentDetails/documentMetadata";
import { SnackbarType } from "../../../models/snackbar";
import { WorkflowChangeType } from "../../../models/workflowChangeType";
import { getNonEmptyDocumentIdentity } from "../../helpers/documentIdentityHelper";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { selectDocumentIdentity } from "../../slices/documentMetadataSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { Workflow } from "../../../models/documentList/types/workflow";
import { useUserApplicationRoles } from "../permissions/roles/useUserApplicationRoles";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

interface SetStateDocumentActionProps {
  onWorkflowChangeLoading: () => void;
  onWorkflowChangeError: () => void;
  onWorkflowChangeFinish: () => void;
  onWorkflowChangeCompleted?: () => void;
}

function useSetStateDocumentAction(props: SetStateDocumentActionProps) {
  const dispatch = useAppDispatch();
  const currentDocumentIdentity = useAppSelector(selectDocumentIdentity);
  const [
    changeWorkflow,
    {
      isLoading: workflowChangeLoading,
      isError: isWorkflowChangeError,
      isSuccess: isWorkflowChangeSuccess,
      isUninitialized: workflowChangeIsUninitialized,
      data: warningMessage,
    },
  ] = useChangeDocumentWorkflowActionMutation();

  const onWorkflowChangeLoading = props.onWorkflowChangeLoading;
  const onWorkflowChangeError = props.onWorkflowChangeError;
  const onWorkflowChangeFinish = props.onWorkflowChangeFinish;
  const onWorkflowChangeCompleted = props.onWorkflowChangeCompleted;
  const { isSuperAdmin, isPublisher } = useUserApplicationRoles();

  const [workflowChangeType, setWorkflowChangeType] = useState<
    WorkflowChangeType | undefined
  >(undefined);

  const postChangeDocumentWorkflow = useCallback(
    (workflowChangeType: WorkflowChangeType, identity?: Identity) => {
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(
        identity ?? currentDocumentIdentity
      );
      setWorkflowChangeType(workflowChangeType);
      void changeWorkflow({
        action: workflowChangeType,
        revisionIdentity: nonEmptyIdentity,
      });
    },
    [currentDocumentIdentity, changeWorkflow]
  );

  const getPublishTooltip = (
    hasAccess: boolean,
    workflow: Workflow,
    isLatest: boolean,
    canChangeToPublish: boolean,
    canDelete: boolean,
    hasUncompletedSections: boolean
  ) => {
    if (isSuperAdmin && workflow === Workflow.Archived && !isLatest) {
      return TooltipMsg.NotLatestRevision;
    }

    if (isSuperAdmin) {
      return "";
    }

    if (!isPublisher) {
      return TooltipMsg.CantPublish;
    }

    if (hasUncompletedSections) {
      return TooltipMsg.UncompletedSelfHelpSections;
    }

    return !hasAccess
      ? TooltipMsg.NoAccessToDocument
      : workflow === Workflow.Archived && !isLatest
        ? TooltipMsg.NotLatestRevision
        : workflow === Workflow.Deleted && !canChangeToPublish
          ? TooltipMsg.WillNotBeLatestRevisionText
          : workflow === Workflow.Deleted && !canDelete
            ? TooltipMsg.UndeleteDocument
            : "";
  };

  const getArchiveTooltip = (
    hasAccess: boolean,
    canDelete: boolean,
    hasUncompletedSections: boolean,
    workflow: Workflow
  ) => {
    if (isSuperAdmin) {
      return "";
    }

    if (!isPublisher) {
      return TooltipMsg.CantPublish;
    }

    if (hasUncompletedSections) {
      return TooltipMsg.UncompletedSelfHelpSections;
    }

    return !hasAccess
      ? TooltipMsg.NoAccessToDocument
      : workflow === Workflow.Deleted && !canDelete
        ? TooltipMsg.UndeleteDocument
        : "";
  };

  const getDeleteTooltip = (
    canDelete: boolean,
    hasAccess: boolean,
    hasUncompletedSections: boolean
  ) => {
    if (isSuperAdmin) {
      return "";
    }

    if (!isPublisher) {
      return TooltipMsg.CantPublish;
    }

    if (hasUncompletedSections) {
      return TooltipMsg.UncompletedSelfHelpSections;
    }

    return !canDelete
      ? TooltipMsg.DeleteDocument
      : !hasAccess
        ? TooltipMsg.NoAccessToDocument
        : "";
  };

  useEffect(() => {
    if (!workflowChangeIsUninitialized && workflowChangeLoading) {
      onWorkflowChangeLoading();
    }
  }, [
    workflowChangeIsUninitialized,
    workflowChangeLoading,
    onWorkflowChangeLoading,
  ]);

  useEffect(() => {
    if (isWorkflowChangeError) {
      onWorkflowChangeError();
      openSnackbar(
        dispatch,
        SnackbarMsg.WorkflowChangeError,
        SnackbarType.error
      );
    }
  }, [dispatch, isWorkflowChangeError, onWorkflowChangeError]);

  useEffect(() => {
    if (!workflowChangeIsUninitialized && !workflowChangeLoading) {
      if (isWorkflowChangeSuccess) {
        if (onWorkflowChangeCompleted) {
          onWorkflowChangeCompleted();
        }

        if (warningMessage) {
          openSnackbar(dispatch, warningMessage, SnackbarType.warning);
        } else {
          let workflowMessage = "";
          switch (workflowChangeType) {
            case WorkflowChangeType.Publish:
              workflowMessage = "published";
              break;
            case WorkflowChangeType.Archive:
              workflowMessage = "archived";
              break;
            case WorkflowChangeType.DeleteAll:
            case WorkflowChangeType.DeleteWithPublish:
            case WorkflowChangeType.Delete:
              workflowMessage = "deleted";
              break;
          }

          openSnackbar(
            dispatch,
            `Document has been ${workflowMessage} successfully`,
            SnackbarType.success
          );
        }
      }

      onWorkflowChangeFinish();
    }
  }, [
    dispatch,
    onWorkflowChangeFinish,
    onWorkflowChangeCompleted,
    workflowChangeType,
    workflowChangeIsUninitialized,
    workflowChangeLoading,
    isWorkflowChangeSuccess,
    warningMessage,
  ]);

  return {
    postChangeDocumentWorkflow,
    getPublishTooltip,
    getArchiveTooltip,
    getDeleteTooltip,
  };
}

export default useSetStateDocumentAction;
