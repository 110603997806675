import { createApi } from "@reduxjs/toolkit/query/react";
import DocumentMetadata, {
  Identity,
} from "../models/documentDetails/documentMetadata";
import { publishApiBaseQuery } from "./baseQueries";

export interface LinkCategoriesRequest {
  documentIdentity: Identity;
  documentChangedCategories: DocumentChangedCategories;
}

export interface CategoryToLink {
  cid: string;
  isPrioritized: boolean;
}

export interface DocumentChangedCategories {
  categoriesToChange: CategoryToLink[];
  categoriesToDelete: string[];
}

export const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    publishNewDocument: builder.mutation<string, DocumentMetadata>({
      query: (documentMetadata) => ({
        url: `Documents`,
        method: "POST",
        body: documentMetadata,
      }),
    }),
    publishNewDocumentDraft: builder.mutation<
      string,
      { metadata: DocumentMetadata; revisionIdentity: Identity }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.revisionIdentity.documentNumber
        )}/${data.revisionIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(
          data.revisionIdentity.documentPart
        )}/Revisions/${data.revisionIdentity.revision}`,
        method: "POST",
        body: data.metadata,
      }),
    }),
    publishNewDocumentRevision: builder.mutation<
      string,
      {
        metadata: DocumentMetadata;
        revisionIdentity: Identity;
        shouldKeepScheduledAction?: boolean;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.revisionIdentity.documentNumber
        )}/${data.revisionIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(data.revisionIdentity.documentPart)}/Revisions?shouldKeepScheduledAction=${data.shouldKeepScheduledAction}`, // eslint-disable-line @typescript-eslint/restrict-template-expressions
        method: "POST",
        body: data.metadata,
      }),
    }),
    publishExistingDocument: builder.mutation<
      string,
      {
        metadata: DocumentMetadata;
        revisionIdentity: Identity;
        shouldOldLinksWorkOnIdentityChange?: boolean;
        shouldKeepScheduledAction?: boolean;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.revisionIdentity.documentNumber
        )}/${data.revisionIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(
          data.revisionIdentity.documentPart
        )}/Revisions/${data.revisionIdentity.revision}?shouldOldLinksWorkOnIdentityChange=${data.shouldOldLinksWorkOnIdentityChange}&shouldKeepScheduledAction=${data.shouldKeepScheduledAction}`, //eslint-disable-line @typescript-eslint/restrict-template-expressions
        method: "PATCH",
        body: data.metadata,
      }),
    }),
    linkCategoriesToDocument: builder.mutation<boolean, LinkCategoriesRequest>({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentIdentity.documentNumber
        )}/${data.documentIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(data.documentIdentity.documentPart)}/${
          data.documentIdentity.revision
        }/Categories`,
        method: "POST",
        body: data.documentChangedCategories,
      }),
    }),
    sendDocumentForApproval: builder.mutation<
      boolean,
      {
        documentMetadata: DocumentMetadata;
        revisionIdentity: Identity;
        isNewDocument?: boolean;
        isNewRevision?: boolean;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.revisionIdentity.documentNumber
        )}/${data.revisionIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(
          data.revisionIdentity.documentPart
        )}/Revisions/${
          data.revisionIdentity.revision
        }/Drafts/ApprovalDocuments?isNewDocument=${
          data.isNewDocument ? "true" : "false"
        }&isNewRevision=${data.isNewRevision ? "true" : "false"}`,
        method: "POST",
        body: data.documentMetadata,
      }),
    }),
    hardDeleteDocument: builder.mutation<boolean, Identity>({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentNumber
        )}/${data.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentPart
        )}/Revisions/${data.revision}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePublishExistingDocumentMutation,
  usePublishNewDocumentDraftMutation,
  usePublishNewDocumentMutation,
  usePublishNewDocumentRevisionMutation,
  useLinkCategoriesToDocumentMutation,
  useSendDocumentForApprovalMutation,
  useHardDeleteDocumentMutation,
} = documentApi;
export default documentApi.reducer;
