import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useCallback, useEffect } from "react";
import { useTheme } from "styled-components";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../app/hooks/hooks";
import { IconButtonControl } from "../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../controls/Dialog/SC/StyledDialogTitle";
import { CircularProgressBar } from "../../controls/ProgressIndicators/CircularProgressBar";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";
import { TooltipMsg } from "../../controls/Tooltips/TooltipMessages";
import ScheduledActionMessage from "../../models/scheduledActionMessage";
import { SnackbarType } from "../../models/snackbar";
import { IconButtonControlDiv } from "../details/contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../details/selfHelpPopup/SC/StyledDialogContentText";
import useScheduleDeleteAll from "./hooks/useScheduleDeleteAll";
import { setShouldRefreshDocumentMetadata } from "../../app/slices/documentDetailsSlice";

interface DeleteAllPopupProps {
  isOpen: boolean;
  message: ScheduledActionMessage;
  onCloseClick: () => void;
  onNoClick: () => void;
  handleScheduleDialogClose: () => void;
}

function DeleteAllPopup(props: DeleteAllPopupProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    hasUnsuccessfulRequests,
    isLoading,
    isSuccess,
    scheduleDeleteAll,
    deleteOnlyFor,
    runCleanup,
  } = useScheduleDeleteAll();
  const onCloseClick = props.onCloseClick;
  const handleScheduleDialogClose = props.handleScheduleDialogClose;
  const onNoClick = props.onNoClick;

  const onDialogCancelClick = useCallback(() => {
    onCloseClick();
    runCleanup();
  }, [onCloseClick, runCleanup]);

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(
        dispatch,
        SnackbarMsg.ScheduledActionSuccess,
        SnackbarType.success
      );
      dispatch(setShouldRefreshDocumentMetadata(true));
      onDialogCancelClick();
      handleScheduleDialogClose();
    }
  }, [dispatch, isSuccess, onDialogCancelClick, handleScheduleDialogClose]);

  const onYesClick = () => {
    scheduleDeleteAll(props.message);
  };

  const onDialogNoClick = useCallback(() => {
    onDialogCancelClick();
    onNoClick();
  }, [onDialogCancelClick, onNoClick]);

  const onDialogCloseClick = useCallback(
    (event: object, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        onDialogCancelClick();
      }
    },
    [onDialogCancelClick]
  );

  const getContent = () => {
    if (hasUnsuccessfulRequests) {
      return (
        <>
          <span>
            There has been an error while scheduling delete action for
            revisions:{" "}
            {deleteOnlyFor.map((x) => x.toLocaleUpperCase()).join(", ")}.
          </span>
          <br />
          <span> Do you want to retry?</span>
        </>
      );
    }

    return (
      <span>
        This document has at least one archived revision. What would you like to
        do with them?
      </span>
    );
  };

  return (
    <StyledDialog
      id={"schedule-delete-all-dialog"}
      open={props.isOpen}
      onClose={onDialogCloseClick}
      maxWidth={"xs"}
    >
      <StyledDialogTitle id={"schedule-delete-all-dialog-title"}>
        {"Deleting document with previous revisions"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"schedule-delete-all-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={onDialogCancelClick}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>{getContent()}</StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <CircularProgressBar
            size={theme.circularProgress.small}
            color="secondary"
          />
        ) : (
          <DialogButton
            id={"schedule-delete-all-dialog-accept-button"}
            color="primary"
            autoFocus
            disabled={isLoading}
            onClick={onYesClick}
          >
            {hasUnsuccessfulRequests ? "Retry" : "Delete all"}
          </DialogButton>
        )}
        {!hasUnsuccessfulRequests && (
          <DialogButton
            id={"schedule-delete-all-dialog-only-this-revision-button"}
            color="primary"
            onClick={onDialogNoClick}
            disabled={isLoading}
          >
            {"Only this revision"}
          </DialogButton>
        )}
        <DialogButton
          id={"schedule-delete-all-dialog-cancel-button"}
          color="secondary"
          onClick={onDialogCancelClick}
          disabled={isLoading}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default DeleteAllPopup;
